.landing-container {
  max-width: 100%;
  overflow-x: hidden;
  background: #121212;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  background-color: #0E1011;
  color: #E9E5E0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.logo-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 60px;
  width: auto;
  object-fit: contain;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-links a {
  color: #E9E5E0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #FF5722;
}

.features-section {
  padding: 4rem 2rem;
  background: #121212;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.features-section h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 3rem;
  color: #E9E5E0;
  opacity: 0.9;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.features-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.feature-card {
  background: #121212;
  padding: 2rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: #E9E5E0;
  min-height: 300px;
  justify-content: space-between;
}

.feature-card-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feature-card p {
  color: #E9E5E0;
}

.feature-card-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.feature-card-header h3 {
  margin: 0;
}

.feature-sections {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feature-section {
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-section h4 {
  color: #81C784;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.how-it-works-section {
  padding: 6rem 2rem;
  background: #121212;
}

.how-it-works-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #4CAF50, #81C784);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step {
  background: #1a1a1a;
  padding: 2.5rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.step h3 {
  color: #81C784;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.step p {
  color: #E9E5E0;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.step ul {
  color: #E9E5E0;
  padding-left: 1.5rem;
}

.step li {
  margin-bottom: 0.5rem;
}

.footer-section {
  padding: 2rem;
  background: #121212;
  color: white;
  text-align: center;
}

.demo-video-section {
  padding: 4rem 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #121212;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  width: 100%;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.demo-video {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  background: #121212;
}

.demo-caption {
  margin-top: 1rem;
  color: rgba(233, 229, 224, 0.5);
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: center;
  max-width: 450px;
  font-style: italic;
}

.minecraft-logo {
  font-size: 2rem;
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  padding: 0.2em;
}

.feature-details {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-details h4 {
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  color: #fff;
}

.feature-details ul {
  list-style-type: none;
  padding-left: 0;
}

.feature-details li {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
  color: #E9E5E0;
  opacity: 0.9;
}

.comparison-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.metric {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.metric-label {
  font-weight: 300;
  color: #E9E5E0;
  opacity: 0.9;
}

.comparison {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
}

.old {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: line-through;
}

.new {
  color: #4CAF50;
  font-weight: bold;
}

.note {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
  margin-top: 0.5rem;
}

.benchmark-context {
  font-size: 0.9rem;
  color: #E9E5E0;
  opacity: 0.8;
  margin-bottom: 1rem;
}

/* Add responsive design for mobile */
@media (max-width: 768px) {
  .video-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .demo-video-section {
    padding: 1rem;
  }
}

/* Remove or modify the media query since we're already in single column */
@media (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0;
  }
  
  .features-section {
    padding: 1rem;
  }
}

.subtitle-container {
  padding: 2rem 1rem;
  text-align: center;
  background: #121212;
}

.subtitle-text {
  color: #E9E5E0;
  font-size: 1.5rem;
  font-weight: 300;
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
}

/* Optional: Add a subtle animation when the text appears */
.subtitle-text {
  animation: fadeIn 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 0.9;
    transform: translateY(0);
  }
}

.video-title {
  color: #E9E5E0;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  text-align: center;
  opacity: 0.9;
}

.benchmark-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 1rem;
}

.benchmark-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
}

.benchmark-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1a1a1a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.benchmark-image:hover {
  transform: scale(1.02);
}

.benchmark-caption {
  font-size: 0.9rem;
  color: rgba(233, 229, 224, 0.5);
  text-align: center;
  line-height: 1.4;
  font-style: italic;
  max-width: 450px;
  margin: 1.5rem auto 0;
}

@media (max-width: 768px) {
  .benchmark-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.benchmark-description {
  color: #E9E5E0;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  opacity: 0.8;
}

.youtube-button {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #282828;
  color: white;
  padding: 6px 12px;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.youtube-button:hover {
  background: #353535;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.subscriber-count {
  font-size: 0.95rem;
  font-weight: 500;
  padding-left: 12px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
}

.youtube-pointer {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #E9E5E0;
  font-size: 0.7rem;
  opacity: 0.6;
  margin-right: 4px;
  animation: bounce 2s infinite;
  margin-top: 15px;
  transform: translateY(5px);
}

.youtube-pointer svg {
  transform: rotate(70deg);
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
}

.other-projects-section {
  padding: 4rem 2rem;
  background: #121212;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.other-projects-section h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 3rem;
  color: #E9E5E0;
  opacity: 0.9;
}

.other-projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .other-projects-grid {
    grid-template-columns: 1fr;
  }
}

.video-fallback-link {
  font-size: 0.8rem;
  color: #666;
  text-decoration: underline;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  text-align: center;
}

.video-fallback-link:hover {
  color: #888;
}

.project-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.project-link {
  display: inline-block;
  color: #E9E5E0;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.05);
  text-align: center;
  margin-top: auto;
}

.project-link:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}